import { render, staticRenderFns } from "./logistics-insurance.vue?vue&type=template&id=7855255b&scoped=true&"
import script from "./logistics-insurance.vue?vue&type=script&lang=ts&"
export * from "./logistics-insurance.vue?vue&type=script&lang=ts&"
import style0 from "./logistics-insurance.vue?vue&type=style&index=0&id=7855255b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7855255b",
  null
  
)

export default component.exports